import { navigate } from 'gatsby';
import React, { memo, useEffect, useState } from 'react';
import { useParams } from '@reach/router';
import firebase from 'gatsby-plugin-firebase';
import ShortUniqueId from 'short-unique-id';
import LoadingScreen from '../../components/router/LoadingScreen';
import Wrapper from '../../components/shared/Wrapper';
import { getUnsplashPhoto } from '../../utils';
import initialState from '../../data/initialState.json';

const getProperTemplate = (template) => {
  switch (template) {
    case 'stockholm':
      return 'onyx';
    case 'new_york':
      return 'pikachu';
    case 'vienna':
      return 'gengar';
    case 'sydney':
      return 'castform';
    case 'london':
      return 'onyx';
    case 'dublin':
      return 'glalie';
    case 'moscow':
      return 'onyx';
    case 'amsterdam':
      return 'pikachu';
    case 'madrid':
      return 'celebi';
    case 'santiago':
      return 'onyx';
    case 'singapore':
      return 'onyx';
    case 'oslo':
      return 'onyx';
    case 'paris':
      return 'glalie';
    case 'berlin':
      return 'pikachu';
    case 'vancouver':
      return 'onyx';
    case 'tokyo':
      return 'onyx';
    case 'milan':
      return 'pikachu';
    case 'barcelona':
      return 'pikachu';
    case 'copenhagen':
      return 'onyx';
    default:
      return 'onyx';
  }
};

const CreateResume = ({ user }) => {
  const [loading, setLoading] = useState(true);
  const params = useParams();
  const dictionary = 'abcdefghijklmnopqrstuvwxyz1234567890'.split('');
  const uuid = new ShortUniqueId({ dictionary });

  const createResume1 = async () => {
    const id = uuid();
    const name = 'My Resume';
    const preview = await getUnsplashPhoto();
    const createdAt = firebase.database.ServerValue.TIMESTAMP;
    const resumeTemplate = getProperTemplate(params.template);
    console.log(params.template);
    console.log(resumeTemplate);

    let firstName;
    let lastName;

    const resume = {
      ...initialState,
      id,
      name,
      user: user.uid,
      preview,
      profile: {
        ...initialState.profile,
        firstName: firstName || '',
        lastName: lastName || '',
      },
      metadata: {
        ...initialState.metadata,
        template: resumeTemplate,
      },
      createdAt,
      updatedAt: createdAt,
    };

    console.log(resume);
    firebase.database().ref(`resumes/${id}`).set(resume);

    return id;
  };

  useEffect(() => {
    (async () => {
      const id = await createResume1();
      console.log(id);
      setLoading(false);
      navigate(`/app/builder/${id}`);
    })();
  }, []);

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <Wrapper>
      <div className="container px-8 xl:px-0 text-center md:text-left mt-24">
        Going to Resume One Resume Builder
      </div>
    </Wrapper>
  );
};

export default memo(CreateResume);
